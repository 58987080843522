import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import {
  DownloadClientsDataInExcel,
  DownloadMultipleClientsDataInExcel,
  getEndOfMonth,
  getStartOfMonth,
} from "../Shared/Utility";
import { useGlobalState } from "../Context/GlobalStateContext";
import ConfirmationModal from "../App/ConfirmationModal";
import MultiSelectionDropdown from "../App/MultiSelectionDropdown";
function DamageClaims() {
  const globalState = useGlobalState();
  const [showModal, setShowModal] = useState(false);
  const [isAnimation, setIsAnimation] = useState(false);
  const [isAddNew, setIsAddNew] = useState(0);
  const [DamageClaimGetHistoricalData, setDamageClaimGetHistoricalDataion] =
    useState<any>([]);

  const [sourceList, setSourceList] = useState<any>([]);

  const [DamageClaimMoversList, setDamageClaimMoversList] = useState<any>([]);

  const [damageClaimObject, setDamageClaimObject] = useState({
    mnvclientid: 0,
    mnvmoverid: 0,
    jobid: "",
    startdate: getStartOfMonth(),
    enddate: getEndOfMonth(),
  });

  const [addEditObject, setAddEditObject] = useState<any>({
    jobid: "",
    servicedate: new Date(),
    claimdate: new Date(),
    description: "",
    mnvclientid: 0,
    damagedoneby: 0,
    outcome: "",
    cost: "",
    insuranceclaimref: "",
    moverslist: "",
  });

  const [reviewSourceTypeList, setReviewSourceTypeList] = useState<any>([]);
  const [reviewSourceTypeListCustom, setReviewSourceTypeListCustom] =
    useState<any>([]);
  const [moversList, setMoversList] = useState([]);
  const [selectedSourceObject, setSelectedSourceObject] = useState<any>({});

  useEffect(() => {
    setDamageClaimObject((prevState: any) => ({
      ...prevState,
      startdate: getStartOfMonth(),
      enddate: getEndOfMonth(),
    }));
    getDamageClaimGetJobDetailsAndMoversList(1);
    getDamageClaimGetHistoricalData();
  }, []);

  useEffect(() => {
    if (isAddNew == 2 && addEditObject.jobid != "")
      getDamageClaimGetJobDetailsAndMoversList(4);
  }, [isAddNew, addEditObject.jobid]);

  function getDamageClaimGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    object.jobid = addEditObject.jobid;
    object.screenname = "damageclaims";
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (typeid == 3 || typeid == 4) {
            if (l_response.length > 0 && l_response[0].length > 0) {
              let reviewdate = new Date();
              if (typeid == 3) {
                if (l_response[0] && l_response[0][0]) {
                  reviewdate = new Date(l_response[0][0].RequestedDate);
                }

                setAddEditObject((prevState: any) => ({
                  ...prevState,
                  servicedate: reviewdate,
                }));
              }
              setDamageClaimMoversList(l_response[1]);
            } else {
              setAddEditObject((prevState: any) => ({
                ...prevState,
                servicedate: "",
              }));
              globalState.setNotificationObject({
                ShowPopup: true,
                Heading: "Alert",
                Message: "It looks like a duplicate entry!",
              });
            }
          }

          if (typeid == 1) {
            setReviewSourceTypeList(l_response[0]);
            setMoversList(l_response[1]);
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function getDamageClaimGetHistoricalData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvclientid = damageClaimObject.mnvclientid;
    object.mnvmoverid = damageClaimObject.mnvmoverid;
    object.jobid = damageClaimObject.jobid;
    object.startdate = damageClaimObject.startdate;
    object.enddate = damageClaimObject.enddate;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVDamageClaim/MNVDamageClaimGetHistoricalData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setDamageClaimGetHistoricalDataion(l_response[0]);
          setSourceList(l_response[1]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function checkValidation() {
    let isValid = true;
    if (addEditObject.jobid == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Job ID Required",
      });
    } else if (addEditObject.servicedate == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Service Date Required",
      });
    } else if (addEditObject.claimdate == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Claim Date Required",
      });
    } else if (addEditObject.description == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Description Required",
      });
    }
    // else if (addEditObject.damagedoneby == 0) {
    //   isValid = false;
    //   globalState.setNotificationObject({
    //     ShowPopup: true,
    //     Heading: "Alert",
    //     Message: "Select Damage Done By",
    //   });
    // } else if (addEditObject.outcome == "") {
    //   isValid = false;
    //   globalState.setNotificationObject({
    //     ShowPopup: true,
    //     Heading: "Alert",
    //     Message: "Outcome Required",
    //   });
    // } else if (addEditObject.cost == "") {
    //   isValid = false;
    //   globalState.setNotificationObject({
    //     ShowPopup: true,
    //     Heading: "Alert",
    //     Message: "Cost Required",
    //   });
    // } else if (addEditObject.insuranceclaimref == "") {
    //   isValid = false;
    //   globalState.setNotificationObject({
    //     ShowPopup: true,
    //     Heading: "Alert",
    //     Message: "Insurance Claim Ref Required",
    //   });
    // }
    else if (
      DamageClaimMoversList.length == 0 ||
      DamageClaimMoversList[0].length == 0
    ) {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Mover(s) Required",
      });
    }

    if (isValid == true) {
      damageClaimSaveCustom();
    }
  }

  function damageClaimSaveCustom() {
    setIsAnimation(true);
    let object = {} as any;

    addEditObject.moverslist = "";
    for (let i = 0; i < DamageClaimMoversList.length; i++) {
      if (DamageClaimMoversList[i].Name) {
        addEditObject.moverslist += DamageClaimMoversList[i].Name + ",";
      }
    }
    if (addEditObject.moverslist != "") {
      addEditObject.moverslist = addEditObject.moverslist.replace(/,$/, "");
    }

    object.jobid = addEditObject.jobid;
    object.servicedate = addEditObject.servicedate;
    object.claimdate = addEditObject.claimdate;
    object.description = addEditObject.description;
    object.damagedoneby = addEditObject.damagedoneby;
    object.outcome = addEditObject.outcome;
    object.cost = addEditObject.cost;
    object.insuranceclaimref = addEditObject.insuranceclaimref;
    object.moversdata = DamageClaimMoversList;
    object.moverslist = addEditObject.moverslist;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVDamageClaim/MNVDamageClaimSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          getDamageClaimGetHistoricalData();
          getDamageClaimGetJobDetailsAndMoversList(1);
          setIsAddNew(0);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function damageClaimSaveCustomV2() {
    setIsAnimation(true);
    let object = {} as any;

    object.mnvdamageclaimid = addEditObject.mnvdamageclaimid;
    object.jobid = addEditObject.jobid;
    object.servicedate = addEditObject.servicedate;
    object.moverslist = addEditObject.moverslist;
    object.claimdate = addEditObject.claimdate;
    object.description = addEditObject.description;
    object.damagedoneby = addEditObject.damagedoneby;
    object.outcome = addEditObject.outcome;
    object.cost = Number(addEditObject.cost);
    object.insuranceclaimref = addEditObject.insuranceclaimref;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVDamageClaim/MNVDamageClaimSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          getDamageClaimGetHistoricalData();
          getDamageClaimGetJobDetailsAndMoversList(1);
          setIsAddNew(0);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function deleteDamageClaimRecord(MNVDamageClaimID: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvdamageclaimid = MNVDamageClaimID;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVDamageClaim/MNVDamageClaimDeleteRecordCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          getDamageClaimGetHistoricalData();
          getDamageClaimGetJobDetailsAndMoversList(1);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        deleteDamageClaimRecord(obj.SelectedID);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
    });
  }

  function updateReviewSource(obj: any) {
    let newList = [];
    for (let index = 0; index < reviewSourceTypeList.length; index++) {
      if (reviewSourceTypeList[index].mnvclientid != obj.mnvclientid) {
        newList.push({
          Name: reviewSourceTypeList[index].Name,
          PID: reviewSourceTypeList[index].mnvclientid,
        });
      }
    }

    let selectedIds = "";
    for (let i = 0; i < sourceList.length; i++) {
      if (sourceList[i].MNVDamageClaimID == obj.MNVDamageClaimID) {
        selectedIds += sourceList[i].mnvclientid + ",";
      }
    }
    if (selectedIds != "") {
      selectedIds = selectedIds.replace(/,$/, "");
    }

    obj.selectedIds = selectedIds;

    setReviewSourceTypeListCustom(newList);

    setSelectedSourceObject(obj);
    setShowModal(true);
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      selectedSourceObject.SourceTypeIds = value;
      setSelectedSourceObject((prevState: any) => ({
        ...prevState,
        SourceTypeIds: value,
      }));
    }
  }

  function updateJobSourceData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvDamageClaimid = selectedSourceObject.MNVDamageClaimID;
    object.sourcetypeids = selectedSourceObject.SourceTypeIds;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/RelMNVDamageClaimAndSourceType/RelMNVDamageClaimAndSourceTypeSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setShowModal(false);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  return (
    <div className="inline-block-d width-100 p-all10">
      {isAnimation && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      {isAddNew == 0 && (
        <>
          <>
            <div className="flex-d justify-btw mb-10">
              <div className="flex-da gap-10">
                <div className="field-input">
                  <select
                    className="input-field"
                    value={damageClaimObject.mnvclientid}
                    onChange={(e) =>
                      setDamageClaimObject((prevState: any) => ({
                        ...prevState,
                        mnvclientid: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Client</option>
                    {reviewSourceTypeList &&
                      reviewSourceTypeList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.PID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <select
                    className="input-field"
                    value={damageClaimObject.mnvmoverid}
                    onChange={(e) =>
                      setDamageClaimObject((prevState: any) => ({
                        ...prevState,
                        mnvmoverid: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Mover</option>
                    {moversList &&
                      moversList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.MNVMoverID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <input
                    className="input-field"
                    placeholder="Job ID"
                    value={damageClaimObject.jobid}
                    onChange={(e) =>
                      setDamageClaimObject((prevState: any) => ({
                        ...prevState,
                        jobid: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    title="Service Start Date"
                    placeholder="Lag"
                    value={damageClaimObject.startdate}
                    onChange={(e) =>
                      setDamageClaimObject((prevState: any) => ({
                        ...prevState,
                        startdate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    title="Service End Date"
                    placeholder="Lag"
                    value={damageClaimObject.enddate}
                    onChange={(e) =>
                      setDamageClaimObject((prevState: any) => ({
                        ...prevState,
                        enddate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="flex-daj btn-start">
                  <a
                    onClick={() => getDamageClaimGetHistoricalData()}
                    className="btn-primary flex-daj"
                  >
                    Filter
                  </a>
                </div>
              </div>

              <div className="flex-daj btn-start gap-10">
                <a
                  onClick={() => {
                    setAddEditObject({
                      jobid: "",
                      servicedate: "",
                      claimdate: "",
                      description: "",
                      mnvclientid: 0,
                      damagedoneby: 0,
                      outcome: "",
                      cost: 0,
                      insuranceclaimref: "",
                      moverslist: "",
                    });
                    setDamageClaimMoversList([]);
                    setIsAddNew(1);
                  }}
                  className="btn-primary flex-daj"
                >
                  Add New
                </a>

                <a
                  className="btn-primary flex-daj btn-grid"
                  title="Export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tblDamageClaim", "Damage Claim")
                  }
                >
                  <img src="images/export.svg" width="18" height="18" />
                </a>
              </div>
            </div>

            <table className="reuse-table" id="tblDamageClaim">
              <thead>
                <tr>
                  <th>Job ID</th>
                  <th>Client Name</th>
                  <th>Service Data</th>
                  <th>Movers</th>
                  <th>Claim Date</th>
                  <th>Description</th>
                  <th>Damage Done By</th>
                  <th>Out Come</th>
                  <th>Cost</th>
                  <th>Insurance Claim Ref</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {DamageClaimGetHistoricalData &&
                  DamageClaimGetHistoricalData.map((Item: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>{Item.JobID}</td>
                        <td>{Item.ClientName}</td>
                        <td>{moment(Item.ServiceDate).format("DD/MM/YYYY")}</td>
                        <td>{Item.MoversList}</td>
                        <td>{moment(Item.ClaimDate).format("DD/MM/YYYY")}</td>
                        <td>{Item.Description}</td>
                        <td>{Item.DamageDoneByName}</td>
                        <td>{Item.Outcome}</td>
                        <td className="text-right">
                          £
                          {Item.Cost.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td>{Item.InsuranceClaimRef}</td>

                        <td className="text-center">
                          <div className="flex-daj gap-10">
                            <a
                              onClick={() => {
                                setAddEditObject({
                                  mnvdamageclaimid: Item.MNVDamageClaimID,
                                  jobid: Item.JobID,
                                  servicedate: Item.ServiceDate,
                                  claimdate: Item.ClaimDate,
                                  description: Item.Description,
                                  damagedoneby: Item.DamageDoneBy,
                                  damagedonebyname: Item.DamageDoneByName,
                                  outcome: Item.Outcome,
                                  cost: Item.Cost,
                                  insuranceclaimref: Item.InsuranceClaimRef,
                                  moverslist: Item.MoversList,
                                });
                                setIsAddNew(2);
                              }}
                            >
                              <img
                                src="images/edit.svg"
                                width="18px"
                                height="18px"
                                alt="Edit"
                              />
                            </a>
                            <a
                              onClick={() =>
                                globalState.setConfirmationModalObject({
                                  ShowPopup: true,
                                  Heading: "Alert",
                                  Message:
                                    "Are your sure, you want to delete this record?",
                                  ID: 1,
                                  SelectedID: Item.MNVDamageClaimID,
                                  OtherID: 0,
                                })
                              }
                            >
                              <img
                                src="images/delete.png"
                                width="18px"
                                height="18px"
                                alt="Delete"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </>
        </>
      )}

      {isAddNew == 1 && (
        <div className="inline-block-d text-center width-100 reuse-border p-all20">
          <>
            <div className="grid-d fr-111 gap-20">
              {/* <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  value={addEditObject.mnvclientid}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      mnvclientid: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Select Review Type</option>
                  {reviewSourceTypeList &&
                    reviewSourceTypeList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.mnvclientid}>
                          {item.Name}
                        </option>
                      </React.Fragment>
                    ))}
                </select>
              </div> */}
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  placeholder="Job ID"
                  title="Job ID"
                  value={addEditObject.jobid}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      jobid: e.target.value,
                    }))
                  }
                  onBlur={() => getDamageClaimGetJobDetailsAndMoversList(3)}
                />
              </div>
              <div className="field-input grid-d">
                <input
                  disabled
                  className="input-field w-100"
                  type="date"
                  title="Service Date"
                  value={moment(addEditObject.servicedate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      servicedate: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  type="date"
                  title="Claim Date"
                  value={moment(addEditObject.claimdate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      claimdate: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <textarea
                  className="input-field w-100"
                  placeholder="Description"
                  title="Description"
                  value={addEditObject.description}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      description: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  value={addEditObject.damagedoneby}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      damagedoneby: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Damage Done By</option>
                  {DamageClaimMoversList &&
                    DamageClaimMoversList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.MNVMoverID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Outcome"
                  title="Outcome"
                  className="input-field w-100"
                  value={addEditObject.outcome}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      outcome: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Cost"
                  title="Cost"
                  className="input-field w-100"
                  type="number"
                  value={addEditObject.cost}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      cost: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Insurance Claim Ref"
                  title="Insurance Claim Ref"
                  className="input-field w-100"
                  value={addEditObject.insuranceclaimref}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      insuranceclaimref: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex-daj gap-10 mt-20">
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => checkValidation()}
                >
                  Save
                </a>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => setIsAddNew(0)}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </div>
      )}

      {isAddNew == 2 && (
        <div className="inline-block-d text-center width-100 reuse-border p-all20">
          <>
            <div className="grid-d fr-111 gap-20">
              <div className="field-input grid-d">
                <input
                  disabled
                  className="input-field w-100"
                  placeholder="Job ID"
                  title="Job ID"
                  value={addEditObject.jobid}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      jobid: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="field-input grid-d">
                <input
                  disabled
                  className="input-field w-100"
                  type="date"
                  title="Service Date"
                  value={moment(addEditObject.servicedate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      servicedate: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  type="date"
                  title="Claim Date"
                  value={moment(addEditObject.claimdate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      claimdate: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <textarea
                  className="input-field w-100"
                  placeholder="Description"
                  title="Description"
                  value={addEditObject.description}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      description: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  title="Damage Done By"
                  value={addEditObject.damagedoneby}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      damagedoneby: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Damage Done By</option>
                  {DamageClaimMoversList &&
                    DamageClaimMoversList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.MNVMoverID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Outcome"
                  title="Outcome"
                  className="input-field w-100"
                  value={addEditObject.outcome}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      outcome: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Cost"
                  title="Cost"
                  className="input-field w-100"
                  type="number"
                  value={addEditObject.cost}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      cost: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <input
                  placeholder="Insurance Claim Ref"
                  title="Insurance Claim Ref"
                  className="input-field w-100"
                  value={addEditObject.insuranceclaimref}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      insuranceclaimref: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex-daj gap-10 mt-20">
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => damageClaimSaveCustomV2()}
                >
                  Update
                </a>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => setIsAddNew(0)}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </div>
      )}

      {showModal && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner sm-popup">
              <div className="help-head flex-da justify-btw">
                <h3 className="bold-font flex-d m-all0">
                  Update Review Source
                </h3>
                <a className="close-help" onClick={() => setShowModal(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message mt-20 p-all0">
                <MultiSelectionDropdown
                  listData={reviewSourceTypeListCustom}
                  selectAllName={"All"}
                  selectFewName={"Source Type"}
                  updateSelectedPIDForParent={updateSelectedPIDForParent}
                  type={1}
                  selectedPID={selectedSourceObject.selectedIds}
                ></MultiSelectionDropdown>
              </div>
              <div className="flex-daj gap-10 mt-20">
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => updateJobSourceData()}
                  >
                    Save
                  </a>
                </div>
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DamageClaims;
