import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import {
  DownloadClientsDataInExcel,
  DownloadMultipleClientsDataInExcel,
  getEndOfMonth,
  getStartOfMonth,
} from "../Shared/Utility";
import { useGlobalState } from "../Context/GlobalStateContext";
import ConfirmationModal from "../App/ConfirmationModal";
import MultiSelectionDropdown from "../App/MultiSelectionDropdown";
function JobReview() {
  const globalState = useGlobalState();
  const [showModal, setShowModal] = useState(false);
  const [isAnimation, setIsAnimation] = useState(false);
  const [isAddNew, setIsAddNew] = useState(0);
  const [jobReviewGetHistoricalData, setJobReviewGetHistoricalDataion] =
    useState<any>([]);

  const [sourceList, setSourceList] = useState<any>([]);

  const [
    jobReviewGetJobDetailsAndMoversList,
    setJobReviewGetJobDetailsAndMoversList,
  ] = useState<any>([]);

  const [jobReviewObject, setJobReviewObject] = useState({
    mnvjobreviewsourcetypeid: 0,
    mnvmoverid: 0,
    jobid: "",
    startdate: getStartOfMonth(),
    enddate: getEndOfMonth(),
  });

  const [addEditObject, setAddEditObject] = useState<any>({
    mnvjobreviewsourcetypeid: 0,
    reviewdate: new Date(),
    jobid: "",
    moverslist: "",
    totalamount: 0,
    permoveramount: 0,
  });

  const [reviewSourceTypeList, setReviewSourceTypeList] = useState<any>([]);
  const [reviewSourceTypeListCustom, setReviewSourceTypeListCustom] =
    useState<any>([]);
  const [moversList, setMoversList] = useState([]);
  const [selectedSourceObject, setSelectedSourceObject] = useState<any>({});

  useEffect(() => {
    setJobReviewObject((prevState: any) => ({
      ...prevState,
      startdate: getStartOfMonth(),
      enddate: getEndOfMonth(),
    }));
    getJobReviewGetJobDetailsAndMoversList(1);
    getJobReviewGetHistoricalData();
  }, []);

  function getJobReviewGetHistoricalData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvjobreviewsourcetypeid = jobReviewObject.mnvjobreviewsourcetypeid;
    object.mnvmoverid = jobReviewObject.mnvmoverid;
    object.jobid = jobReviewObject.jobid;
    object.startdate = jobReviewObject.startdate;
    object.enddate = jobReviewObject.enddate;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVJobReview/MNVJobReviewGetHistoricalData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setJobReviewGetHistoricalDataion(l_response[0]);
          setSourceList(l_response[1]);
          console.log(l_response);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function getJobReviewGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    object.jobid = addEditObject.jobid;
    object.screenname = "jobreview";
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (typeid == 2) {
            if (l_response.length > 0) {
              //extract from table and assign
              let reviewdate = new Date();
              let totalamount = 0;
              let permoveramount = 0;

              if (l_response[0] && l_response[0][0]) {
                reviewdate = new Date(l_response[0][0].RequestedDate);
              }

              if (l_response[1] && l_response[1][0]) {
                totalamount = l_response[1][0].TotalAmount;
                permoveramount = l_response[1][0].PerMoverAmount;
              }

              setAddEditObject((prevState: any) => ({
                ...prevState,
                reviewdate: reviewdate,
                totalamount: totalamount,
                permoveramount: permoveramount,
              }));
              setJobReviewGetJobDetailsAndMoversList(l_response);
            } else
              globalState.setNotificationObject({
                ShowPopup: true,
                Heading: "Alert",
                Message: "It looks like a duplicate entry!",
              });
          }

          if (typeid == 1) {
            setReviewSourceTypeList(l_response[0]);
            setMoversList(l_response[1]);
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function checkValidation() {
    let isValid = true;
    if (addEditObject.mnvjobreviewsourcetypeid == 0) {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Review Type Required",
      });
    } else if (addEditObject.jobid == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Job ID Required",
      });
    } else if (addEditObject.reviewdate == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Date Required",
      });
    } else if (
      jobReviewGetJobDetailsAndMoversList.length == 0 ||
      jobReviewGetJobDetailsAndMoversList[1].length == 0
    ) {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Mover(s) Required",
      });
    }

    if (isValid == true) {
      jobReviewSaveCustom();
    }
  }

  function jobReviewSaveCustom() {
    setIsAnimation(true);
    let object = {} as any;

    addEditObject.moverslist = "";
    for (let i = 0; i < jobReviewGetJobDetailsAndMoversList[1].length; i++) {
      if (jobReviewGetJobDetailsAndMoversList[1][i].Name) {
        addEditObject.moverslist +=
          jobReviewGetJobDetailsAndMoversList[1][i].Name + ",";
      }
    }
    if (addEditObject.moverslist != "") {
      addEditObject.moverslist = addEditObject.moverslist.replace(/,$/, "");
    }

    object.mnvjobreviewsourcetypeid = addEditObject.mnvjobreviewsourcetypeid;
    object.reviewdate = addEditObject.reviewdate;
    object.jobid = addEditObject.jobid;
    object.moverslist = addEditObject.moverslist;
    object.totalamount = addEditObject.totalamount;
    object.permoveramount = addEditObject.permoveramount;

    object.moversdata = jobReviewGetJobDetailsAndMoversList[1];

    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVJobReview/MNVJobReviewSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          getJobReviewGetHistoricalData();
          setIsAddNew(0);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function deleteJobReviewRecord(MNVJobReviewID: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvjobreviewid = MNVJobReviewID;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVJobReview/MNVJobReviewDeleteRecordCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          getJobReviewGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        deleteJobReviewRecord(obj.SelectedID);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
    });
  }

  function updateReviewSource(obj: any) {
    let newList = [];
    for (let index = 0; index < reviewSourceTypeList.length; index++) {
      if (
        reviewSourceTypeList[index].MNVJobReviewSourceTypeID !=
        obj.MNVJobReviewSourceTypeID
      ) {
        newList.push({
          Name: reviewSourceTypeList[index].Name,
          PID: reviewSourceTypeList[index].MNVJobReviewSourceTypeID,
        });
      }
    }

    let selectedIds = "";
    for (let i = 0; i < sourceList.length; i++) {
      if (sourceList[i].MNVJobReviewID == obj.MNVJobReviewID) {
        selectedIds += sourceList[i].MNVJobReviewSourceTypeID + ",";
      }
    }
    if (selectedIds != "") {
      selectedIds = selectedIds.replace(/,$/, "");
    }

    obj.selectedIds = selectedIds;

    setReviewSourceTypeListCustom(newList);

    setSelectedSourceObject(obj);
    setShowModal(true);
  }

  function updateSelectedPIDForParent(type: any, value: any) {
    if (type == 1) {
      selectedSourceObject.SourceTypeIds = value;
      setSelectedSourceObject((prevState: any) => ({
        ...prevState,
        SourceTypeIds: value,
      }));
    }
  }

  function updateJobSourceData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvjobreviewid = selectedSourceObject.MNVJobReviewID;
    object.sourcetypeids = selectedSourceObject.SourceTypeIds;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/RelMNVJobReviewAndSourceType/RelMNVJobReviewAndSourceTypeSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setShowModal(false);
          getJobReviewGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  return (
    <div className="inline-block-d width-100 p-all10">
      {isAnimation && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      {isAddNew == 0 && (
        <>
          <>
            <div className="flex-d justify-btw mb-10">
              <div className="flex-da gap-10">
                <div className="field-input">
                  <select
                    className="input-field"
                    value={jobReviewObject.mnvjobreviewsourcetypeid}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        mnvjobreviewsourcetypeid: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Review Type</option>
                    {reviewSourceTypeList &&
                      reviewSourceTypeList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.MNVJobReviewSourceTypeID}>
                            {item.Name}
                          </option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <select
                    className="input-field"
                    value={jobReviewObject.mnvmoverid}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        mnvmoverid: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Mover</option>
                    {moversList &&
                      moversList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.MNVMoverID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <input
                    className="input-field"
                    placeholder="Job ID"
                    value={jobReviewObject.jobid}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        jobid: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    placeholder="Lag"
                    value={jobReviewObject.startdate}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        startdate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    placeholder="Lag"
                    value={jobReviewObject.enddate}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        enddate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="flex-daj btn-start">
                  <a
                    onClick={() => getJobReviewGetHistoricalData()}
                    className="btn-primary flex-daj"
                  >
                    Filter
                  </a>
                </div>
              </div>

              <div className="flex-daj btn-start gap-10">
                <a
                  onClick={() => {
                    setAddEditObject({
                      mnvjobreviewsourcetypeid: 0,
                      reviewdate: "",
                      jobid: "",
                      moverslist: "",
                      totalamount: 0,
                      permoveramount: 0,
                    });
                    setJobReviewGetJobDetailsAndMoversList([]);
                    setIsAddNew(1);
                  }}
                  className="btn-primary flex-daj"
                >
                  Add New
                </a>

                <a
                  className="btn-primary flex-daj btn-grid"
                  title="Export"
                  onClick={() =>
                    DownloadClientsDataInExcel("tbljobreview", "Job Review")
                  }
                >
                  <img src="images/export.svg" width="18" height="18" />
                </a>
              </div>
            </div>

            <table className="reuse-table" id="tbljobreview">
              <thead>
                <tr>
                  <th>Review Source</th>
                  <th>Date Of Review</th>
                  <th>Job ID</th>
                  <th>Movers</th>
                  <th>Total Amount</th>
                  <th>Each Mover Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {jobReviewGetHistoricalData &&
                  jobReviewGetHistoricalData.map((Item: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>
                          {Item.MNVJobReviewSourceTypeName}
                          <span>
                            {sourceList &&
                              sourceList.map((s_item: any, s_index: any) => (
                                <React.Fragment key={s_index}>
                                  {Item.MNVJobReviewID ==
                                    s_item.MNVJobReviewID && (
                                    <span>, {s_item.Name}</span>
                                  )}
                                </React.Fragment>
                              ))}
                          </span>
                        </td>
                        <td>{moment(Item.ReviewDate).format("DD/MM/YYYY")}</td>
                        <td>{Item.JobID}</td>
                        <td>{Item.MoversList}</td>
                        <td className="text-right">
                          £
                          {Item.TotalAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">
                          £
                          {Item.PerMoverAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-center">
                          <div className="flex-daj gap-10">
                            <a
                              onClick={() => updateReviewSource(Item)}
                              title="Update Review Source"
                            >
                              <img
                                src="images/add.svg"
                                width="18px"
                                height="18px"
                                alt="Add"
                              />
                            </a>

                            <a
                              onClick={() =>
                                globalState.setConfirmationModalObject({
                                  ShowPopup: true,
                                  Heading: "Alert",
                                  Message:
                                    "Are your sure, you want to delete this record?",
                                  ID: 1,
                                  SelectedID: Item.MNVJobReviewID,
                                  OtherID: 0,
                                })
                              }
                            >
                              <img
                                src="images/delete.png"
                                width="18px"
                                height="18px"
                                alt="Delete"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </>
        </>
      )}

      {(isAddNew == 1 || isAddNew == 2) && (
        <div className="inline-block-d text-center width-100 reuse-border p-all20">
          <>
            <div className="grid-d fr-111 gap-20">
              <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  value={addEditObject.mnvjobreviewsourcetypeid}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      mnvjobreviewsourcetypeid: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Select Review Type</option>
                  {reviewSourceTypeList &&
                    reviewSourceTypeList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.MNVJobReviewSourceTypeID}>
                          {item.Name}
                        </option>
                      </React.Fragment>
                    ))}
                </select>
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  placeholder="Job ID"
                  value={addEditObject.jobid}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      jobid: e.target.value,
                    }))
                  }
                  onBlur={() => getJobReviewGetJobDetailsAndMoversList(2)}
                />
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  type="date"
                  value={moment(addEditObject.reviewdate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      reviewdate: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  disabled
                  value={"£" + addEditObject.totalamount}
                />
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  disabled
                  value={"£" + addEditObject.permoveramount}
                />
              </div>
            </div>

            {jobReviewGetJobDetailsAndMoversList[0] &&
              jobReviewGetJobDetailsAndMoversList[1] &&
              jobReviewGetJobDetailsAndMoversList[1].length > 0 && (
                <div className="flex-daj mt-20 ">
                  <div className="reuse-width sm-wid">
                    <table className="reuse-table fixed-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Each Mover Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobReviewGetJobDetailsAndMoversList[1] &&
                          jobReviewGetJobDetailsAndMoversList[1].map(
                            (Item: any, index: any) => (
                              <React.Fragment key={index}>
                                <tr key={index}>
                                  <td className="text-left">{Item.Name}</td>
                                  <td className="text-right">
                                    £
                                    {Item.PerMoverAmount.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            <div className="flex-daj gap-10 mt-20">
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => checkValidation()}
                >
                  {isAddNew == 1 ? "Save" : "Update"}
                </a>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => setIsAddNew(0)}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </div>
      )}

      {showModal && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner sm-popup">
              <div className="help-head flex-da justify-btw">
                <h3 className="bold-font flex-d m-all0">
                  Update Review Source
                </h3>
                <a className="close-help" onClick={() => setShowModal(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message mt-20 p-all0">
                <MultiSelectionDropdown
                  listData={reviewSourceTypeListCustom}
                  selectAllName={"All"}
                  selectFewName={"Source Type"}
                  updateSelectedPIDForParent={updateSelectedPIDForParent}
                  type={1}
                  selectedPID={selectedSourceObject.selectedIds}
                ></MultiSelectionDropdown>
              </div>
              <div className="flex-daj gap-10 mt-20">
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => updateJobSourceData()}
                  >
                    Save
                  </a>
                </div>
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobReview;
